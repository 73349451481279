<template>
	<SignIn />
</template>
<script>
import SignIn from '@/modules/signIn/SignIn'
export default {
	components: {
		SignIn
	}
}
</script>
