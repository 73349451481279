<template>
	<v-card class="rounded-0 elevation-0 pa-8" height="100%">
		<div class="d-flex flex-column justify-space-between">
			<div>
				<TitleForm type="signIn" />
				<v-card-text class="pa-8 rounded-0">
					<v-form ref="form">
						<v-text-field
							dense
							ref="email"
							placeholder="Nhập email"
							:rules="[$rules.required, $rules.email]"
							v-model.trim="form.email"
							@keyup.native.enter="signIn"
							outlined
						></v-text-field>
						<v-text-field
							dense
							:type="showPass ? 'text' : 'password'"
							:append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
							hint="Mậu khẩu chứa ít nhất 6 ký tự"
							:rules="[$rules.required, $rules.minLength(6)]"
							@click:append="showPass = !showPass"
							v-model.trim="form.password"
							placeholder="Nhập mật khẩu"
							@keyup.native.enter="signIn"
							ref="password"
							outlined
						></v-text-field>
					</v-form>
					<div class="d-flex justify-end align-center">
						<BaseButton
							class="elevation-0"
							type="submit"
							text="Đăng nhập"
							@click="signIn"
							color="primary"
							icon="mdi-account-arrow-right"
						/>
					</div>
				</v-card-text>
			</div>

			<div
				@click="$router.push('/auth/forgotpass')"
				class="primary--text cursor-pointer d-flex justify-center"
			>
				Quên mật khẩu?
			</div>
		</div>
	</v-card>
</template>
<script>
import {mapActions} from 'vuex'
import TitleForm from '@/modules/formInformation/TitleForm'
import Base64 from '@/helpers/encodeBase64.js'
export default {
	components: {
		TitleForm
	},
	data() {
		return {
			form: {
				email: '',
				password: ''
			},
			isDisable: false,
			showPass: false
		}
	},
	methods: {
		...mapActions({
			userSignIn: 'auth/signIn'
		}),
		async signIn() {
			if (!this.$refs.form.validate()) return
			try {
				this.$message.loading('Đang đăng nhập...')
				const authUser = await this.userSignIn({
					email: this.form.email,
					password: Base64.encode(this.form.password),
					vendorId: 'super-vendor'
				})
				this.$message.success('Đăng nhập thành công !')
				if (authUser) this.$router.push('/domain')
			} catch (error) {
				this.$message.error(error)
			}
		}
	}
}
</script>
